<template lang="pug">

.input(:class="[{'has-icon': !!icon}, size]")
  .icon(v-if="icon"): i(:class="'mdi mdi-'+icon")
  input(:type="compType", :placeholder="placeholder", :pattern="pattern", :min="min", :max="max", :name="name", :value="value", :disabled="disabled", :readonly="readonly", @input="updateValue($event.target.value)" data-input)
</template>

<script>

export default {
  props: ["type","icon","placeholder","value", "size", "disabled", "name", "readonly", "pattern", "min", "max"],
  mounted() {},
  data() {
    return {}
  },
  computed: {
    compType: function() {
      return this.type || "text";
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    }
  }
}

</script>

<style lang="sass" scoped>
@import '../styles/variables.sass'
.input
  position: relative
  .icon
    height: 18px
    width: 18px
    position: absolute
    top: 0
    bottom: 0
    left: 10px
    margin: auto
    font-size: 18px
    line-height: 18px
    color: #CBCFD4
    pointer-events: none
  &.large input
    padding: 10px 0 10px 10px
  &.has-icon input
    padding-left: 32px
  &.has-copy input
    padding-right: 32px
  input
    border: 1px solid #B9BFC5
    border-radius: 2px
    padding: 7px 0 7px 10px
    font: 14px/16px Roboto, sans-serif
    width: 100%
    margin: 0
    transition: border 0.15s
    -webkit-appearance: none
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    outline: none
    &:focus
      border-color: $primaryColor
    &.selected
      border-color: $successColor  
.form .input input
  padding-top: 11px
  padding-bottom: 11px
</style>