function ovpFetch(url, options) {
  function getHeaders(rawHeaders) {
    let headers = {};
    rawHeaders.trim().split(/[\r\n]+/).forEach((line) => {
      let parts = line.split(': ');
      let header = parts.shift();
      let value = parts.join(': ');
      headers[header] = value;
    });
    return headers;
  }

  options = (typeof options == 'undefined') ? {} : options;

  return new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest();
    if (url.substr(0,4) != 'http') {
      url = process.env.API_URL + url;
    };

    xhr.open((options.method) ? options.method : 'GET', url);
    xhr.onload = function() {
      if (this.readyState == 4 && this.status >= 200 && this.status < 300) {
        let headers = getHeaders(xhr.getAllResponseHeaders());
        if (options.iLikeItRaw) {
          resolve({
            data: xhr.response,
            headers
          });
        } else {
          try {
            resolve({
              data: JSON.parse(xhr.response),
              headers
            });
          } catch(ex) {
            reject({
              error: 'Could not parse json',
              status: this.status,
              headers
            });
          }
        }
      } else {
        let headers = getHeaders(xhr.getAllResponseHeaders());
        if (options.iLikeItRaw) {
          reject({
            status: this.status,
            error: xhr.statusText,
            headers
          });
        } else {
          try {
            reject({
              error: JSON.parse(xhr.response),
              status: this.status,
              headers
            });
          } catch(ex) {
            reject({
              status: this.status,
              error: xhr.statusText,
              headers
            });
          }
        }
      }
    };

    xhr.onerror = function() {
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    
    let acceptHeader = 'application/vnd.streamingbolaget+json';
    let contentTypeHeader = 'application/json; charset=utf-8';
    
    if (options.headers) {
      Object.keys(options.headers).forEach(function (key) {
        if (key === 'Accept') {
          acceptHeader = options.headers[key];
        } else if (key === 'Content-Type') {
          contentTypeHeader = options.headers[key];
        } else {
          xhr.setRequestHeader(key, options.headers[key]);
        }
      });
    }

    xhr.setRequestHeader('Accept', acceptHeader);
    xhr.setRequestHeader('Content-Type', contentTypeHeader);

    if (window.auth) {
      xhr.setRequestHeader('Authorization', `token ${window.auth.token}`);
    }

    if (options.body) {
      xhr.send(options.body);
    } else {
      xhr.send();
    }
  });
}

export {
  ovpFetch
};