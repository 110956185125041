<template lang="pug">
.broadcast(v-if="broadcast")
  .preview
    div(v-html="showPlayer")
    div(v-if="isItTranscoded()").transcodeSwitcher
      p(v-if="!showTranscoded")
        span(@click="showTranscoded = !showTranscoded") Switch to transcoded version
      p(v-else)
        span(@click="showTranscoded = !showTranscoded") Switch to stream
  .mw
    .p-y-4
      h1(style="font-size: 2rem; line-height: 2.2rem") {{ broadcast.metadata.title }}
      ul.details
        li Account sending: {{ account.name }}
        li FQDN: {{ account.fqdn }}
        li Starting: {{ startTime }}
        template(v-if="broadcast.status.life_cycle_status === 'complete'")
          li Ended: {{ endTime }}
        li PPV: {{ isItPPV }}
</template>

<script>

import moment from 'moment';

export default {
  props: ['id'],
  data() {
    return {
      broadcast: null,
      account: null,
      interval: null,
      fqdn: null,
      accountName: null,
      showTranscoded: false
    }
  },

  async mounted() {
    await this.fetchBroadcast();
    this.startTimer();
  },

  beforeDestroy() {
    clearTimeout(this.interval);
  },

  computed: {},

  methods: {
    async fetchBroadcast() {
      await ovpFetch(`/live/broadcast/${this.id}`).then(async (broadcast) => {
        await ovpFetch(`/accounts/${broadcast.data.account_id}`).then((account) => {
          this.account = account.data;
        });
        this.broadcast = broadcast.data;
      });
    },
    
    isItTranscoded: function() {
      let yes = false;
      switch (this.broadcast.status.life_cycle_status) {
        case 'live':
        case 'liveStopping':
        case 'complete':
          yes = true;
          break;
      }

      return yes;
    },

    startTimer() {
      let timer = 5000;

      switch (this.broadcast.status.life_cycle_status) {
        case 'created':
        case 'ready':
        case 'testStarting':
        case 'testing':
        case 'testStopping':
        case 'liveStarting':
          timer = 5000
          break;
        case 'live':
        case 'liveStopping':
          timer = 20000
          break;
        case 'complete':
        case 'reclaimed':
        case 'revoked':
        case 'abandoned':
          timer = undefined
      }

      clearTimeout(this.interval);

      if (timer == undefined) {
        return;
      }

      this.interval = setTimeout(this.timerEnds, timer);
    },

    async timerEnds() {
      await this.fetchBroadcast();
      this.startTimer();
    }
  },

  computed: {
    showPlayer() {
      // Build in so we can switch later..
      if (this.broadcast == null) return '';

      if (this.isItTranscoded()) {
        this.showTranscoded = true;
        let accountName = this.broadcast.fqdn.split('.')[0];
        return `<iframe src="https://${this.account.fqdn}/preview/${this.broadcast.id}-${this.account.fqdn.replace(/.streamingbolaget.se/g,'')}" width="100%" scrolling="no" frameborder="0" allowfullscreen></iframe>`;
      }

      return this.broadcast.broadcast_details.monitor_stream.embed_html;
    },

    startTime() {
      return moment(this.broadcast.scheduled_start_time).format('YYYY-MM-DD HH:mm')
    },

    endTime() {
      return moment(this.broadcast.actual_end_time).format('YYYY-MM-DD HH:mm')
    },

    isItPPV() {
      return (this.broadcast.protected.pay_per_view) ? 'Yes' : 'No';
    }
  }
}
</script>

<style lang="sass">
.preview
  padding-top: 56.25%
  position: relative
  width: 100%
  object, iframe
    bottom: 0
    left: 0
    position: absolute
    right: 0
    top: 0
    height: 100%
</style>

<style lang="sass" scoped>
.preview
  .transcodeSwitcher
    background-color: rgba(44, 44, 84, 0.8)
    color: white
    border-radius: 3px
    padding: 3px 5px
    position: absolute;
    z-index: 2
    top: 15px
    left: 15px
    font-size: 12px
    line-height: 14px
    text-transform: uppercase
    cursor: pointer

.details
  padding: 20px 20px
  font-size: 18px
  list-style: circle
  li
    margin-bottom: 10px
</style>