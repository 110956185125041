<template lang="pug">
.animatedNumber {{ displayNumber }}
</template>

<script>
export default {
  props: ['number', 'intervalLength'],
  data() {
    return {
      displayNumber: 0,
      interval: false
    }
  },

  ready() {
    this.displayNumber = this.number ? this.number : 0;
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  watch: {
    number() {
      clearInterval(this.interval);
      
      if (this.number == this.displayNumber) {
        return;
      }

      this.interval = setInterval(() => {
        if (this.displayNumber != this.number) {
          let change = (this.number - this.displayNumber) / 10;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayNumber = this.displayNumber + change;
        }
      }, (this.intervalLength) ? this.intervalLength : 20);
    }
  }
}
</script>

<style lang="sass" scoped>
.animatedNumber
  font-family: 'Open Sans'
</style>