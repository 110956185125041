<template lang="pug">
.cashbox.d-f.ai-c.p-a-3
  i(:data-type="gradient").mdi.mdi-cash-usd.m-r-2.c-icon-gradient.icon
  .title {{ title }}
  ovp-animated-number(:number="number").ml-auto
  span &nbsp;SEK
</template>
<script>
export default {
  props: ['title', 'number', 'gradient']
}
</script>
<style lang="sass" scoped>
.cashbox
  background-color: rgba(64, 64, 121, 0.55)
  border-radius: 3px
  color: white
  font-size: 16px
  width: 100%
  .icon
    font-size: 22px
</style>