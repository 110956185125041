<template lang="pug">
  span.broadcast-status(:class="value") {{status}}
</template>

<script>

export default {
  props: ["value"],
  computed: {
    status: function() {
      switch (this.value) {
  			case "created":
  				return 'Created';
  			case "ready":
  				return 'Ready';
  			case "testStarting":
  				return 'Test starting';
  			case "testing":
  				return 'Testing';
  			case "liveStarting":
  				return 'Starting';
  			case "live":
  				return 'Live';
  			case "liveStopping":
  				return 'Stopping';
  			case "complete":
  				return 'Completed';
  			case "abandoned":
  				return 'Abandoned';
  			case "error":
  				return 'Error';
  		}

      return this.value
    }
  },
  methods: {

  }
}

</script>

<style lang="sass" scoped>

@import '../styles/variables.sass'

.broadcast-status
  display: inline-block
  position: relative
  padding-left: 10px

  &:before
    content: ""
    height: 7px
    width: 7px
    border-radius: 50%
    position: absolute
    left: 0
    top: -2px
    bottom: 0
    margin: auto
    background-color: rgba($mutedColor, 1)
  
  &.live
    text-transform: uppercase
    color: $alertColor
    font-weight: bold
    &:before
      background-color: $successColor

</style>
