import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './main.vue';
import { ovpFetch } from './utils';
import * as firebase from 'firebase/app';
import 'firebase/auth';

if (process.env.NODE_ENV == 'development') {
  initBackoffice();
} else {
  const config = {
    apiKey: "AIzaSyB5jWmFw6QumVTB_L3njbjeIFXjQjvo2N8",
    authDomain: "streamify-backoffice.firebaseapp.com",
    databaseURL: "https://streamify-backoffice.firebaseio.com",
    projectId: "streamify-backoffice",
    storageBucket: "streamify-backoffice.appspot.com",
    messagingSenderId: "802046719970"
  };
  firebase.initializeApp(config);
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({hd: 'streamify.io'});

  firebase.auth().getRedirectResult().then(function(result) {
    if (!result.user) throw "notloggedin";
    initBackoffice();
  }).catch(function() {
    firebase.auth().signInWithRedirect(provider);
  });
}

function initBackoffice() {
  Vue.use(VueRouter);
  window.ovpFetch = ovpFetch;

  Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      el.event = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.event)
    },
    unbind: function (el) {
      document.body.removeEventListener('click', el.event)
    },
  });

  const components = [
    'graph',
    'animated-number',
    'input',
    'datetime',
    'broadcast-status',
    'broadcast-list-item',
    'stats-box',
    'list-box',
    'cash-box'
  ];

  components.forEach((name) => {
    Vue.component(`ovp-${name}`, require(`./components/${name}.vue`).default);
  });

  const routes = [
    {
      path: '/',
      redirect: '/dashboard'
    },
    {
      path: '/dashboard',
      component: require('./routes/dashboard.vue').default,
      meta: {
        title: 'Dashboard'
      }
    },
    {
      path: '/customer/:id/:accountid?',
      component: require('./routes/customer.vue').default,
      props: true,
      meta: {
        title: 'Customer'
      }
    },
    {
      path: '/broadcast/:id',
      component: require('./routes/broadcast.vue').default,
      props: true,
      meta: {
        title: 'Broadcast'
      }
    }
  ];

  const router = new VueRouter({
    routes, mode: 'history'
  });
  router.beforeEach((to, from, next) => {
    window.globalDelay = 0;
    next();
  });

  new Vue({
    el: '.app',
    router,
    render: h => h(App)
  });
}