<template lang="pug">
.customer-broadcast-count
  template(v-if="upcoming == null && past == null")
    span Upcoming:&nbsp;
      i(class="mdi mdi-loading mdi-spin")
    span Past:&nbsp;
      i(class="mdi mdi-loading mdi-spin")
  template(v-else)
    span Upcoming: {{ upcoming }}
    span Past: {{ past }}
</template>

<script>
export default {
  props: ['id'],
  data() {
    return {
      upcoming: null,
      past: null
    }
  },

  mounted() {
    this.fetchBroadcastCount();
  },

  methods: {
    async fetchBroadcastCount() {
      let count = await ovpFetch(`/live/broadcast/count?account_id=${this.id}`).catch(() => {
        this.upcoming = '?';
        this.past = '?';
      });
      
      if (typeof count !== 'undefined') {
        this.upcoming = count.data.upcoming;
        this.past = count.data.past;
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.customer-broadcast-count
  margin-left: 5px
  span:last-child
    margin-left: 5px
</style>
