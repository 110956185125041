<template lang="pug">
.pagination-container(v-if="!hide")
  .pagination
    span.pager.previously(@click="previously", :class="{'disabled': pageNumber == 1}")
      i(class="mdi mdi-chevron-left")
    span.pages {{ pageNumber }} of {{ maxPage }}
    span.pager.next(@click="next", :class="{'disabled': pageNumber == maxPage}")
      i(class="mdi mdi-chevron-right")
</template>

<script>
export default {
  props: ['link'],
  data() {
    return {
      hide: true,
      pageNumber: 1,
      maxPage: 1
    }
  },

  watch: {
    link: {
      handler(data) {
        this.calculatePages(data);
      },
      deep: true
    }
  },

  methods: {
    next() {
      if (this.maxPage > this.pageNumber) {
        this.pageNumber++
        this.$emit('pagenumber', this.pageNumber);
      }
    },

    previously() {
      if (this.pageNumber > 1) {
        this.pageNumber--;
        this.$emit('pagenumber', this.pageNumber);
      } 
    },

    calculatePages(data) {
      this.hide = (data.linkHeader != null) ? false : true;
      if (!this.hide) {
        this.pageNumber = data.page;
        let exploded = data.linkHeader.split(',');
        if (exploded[exploded.length-1].includes('rel="last"')) {
          let maxPage = parseInt(exploded[exploded.length-1].match(/(?:&page=|\?page=)(\d+)/)[1]); // + 1?
          this.maxPage = maxPage;
          if (maxPage < this.pageNumber) {
            this.pageNumber = maxPage;
            this.$emit('pagenumber', maxPage);
          }
        }
      }
      // Last will be omitted if you are on the last page
      // All rel's will be omitted if the page you are on doesnt exist
      // Just so we know..
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../styles/variables.sass';
.pagination-container
  &.top
    border-bottom: 1px solid #EBEEF0
  &.bottom
    border-top: 1px solid #EBEEF0
.pagination
  align-items: center
  justify-content: center
  display: flex
.pager
  background: $primaryColor
  color: #fff
  cursor: pointer
  display: inline-block
  padding: 6px 8px 2px
  font-weight: 500
  border-radius: 3px
  line-height: 24px
  text-align: center
  transition: background 0.15s, padding 0.15s, color 0.15s
  user-select: none
  i
    font-size: 20px
    line-height: 20px;
  &.next
    border-radius: 0 3px 3px 0
  &.previously
    border-radius: 3px 0 0 3px
  &:hover
    background: darken($primaryColor, 10%)
  &.disabled
    background: $primaryColor
    color: rgba(#fff, 0.5)
.pages
  background: $primaryColor
  border-left: 1px solid white
  border-right: 1px solid white
  color: #fff
  font-size: 14px
  line-height: 20px
  padding: 7px 15px 5px
</style>