import { render, staticRenderFns } from "./customer-broadcast-count.vue?vue&type=template&id=bf7d9fcc&scoped=true&lang=pug"
import script from "./customer-broadcast-count.vue?vue&type=script&lang=js"
export * from "./customer-broadcast-count.vue?vue&type=script&lang=js"
import style0 from "./customer-broadcast-count.vue?vue&type=style&index=0&id=bf7d9fcc&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf7d9fcc",
  null
  
)

export default component.exports