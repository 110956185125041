<template lang="pug">
.flatpickr
  ovp-input(:type="type", :icon="icon", :placeholder="placeholder", :name="name", :value="value", :disabled="disabled")
  span(data-toggle) {{ this.value }}
</template>

<script>
import flatpickr from 'flatpickr'
export default {
  props: ["type","icon","placeholder","value", "size", "disabled", "name", "disableTime", "minDate", "maxDate"],
  mounted() {
    this.pickr = flatpickr(this.$el, {
      enableTime: (this.disableTime) ? false : true,
      weekNumbers: true,
      time_24hr: true,
      onChange: this.flatpickrUpdateValue,
      wrap: true,
      appendTo: this.$el
    });

    if (this.minDate) {
      this.pickr.set('minDate', this.minDate);
    }
    if (this.maxDate) {
      this.pickr.set('maxDate', this.maxDate);
    }
  },
  computed: {
    compType: function() {
      return this.type || "text";
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value);
    },
    flatpickrUpdateValue: function (selectedDates, dateStr, instance) {
      this.updateValue(dateStr)
    }
  },
  watch: {
    minDate(date) {
      this.pickr.set('minDate', date);
    },
    maxDate(date) {
      this.pickr.set('maxDate', date);
    }
  }
}
</script>

<style lang="sass" scoped>
.flatpickr
  position: relative
.input
  display: none
</style>

<style lang="sass">
.flatpickr-calendar
  top: 24px !important
</style>