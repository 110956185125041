<template lang="pug">
li(:key="broadcast.id")
  a(:href="`/broadcast/${broadcast.id}`", @click.prevent="goToBroadcast(broadcast.id, $event)").p-y-2.p-x-3
    .broadcastTitle {{ broadcast.metadata.title }}
    .desc
      ovp-broadcast-status(v-if="!broadcast.loading", :value="broadcast.status.life_cycle_status")
      | &nbsp;
      strong(v-html="descriptionFormatter(broadcast.scheduled_start_time)")
      | &nbsp;
      span(v-html="descriptionDatetime(broadcast.scheduled_start_time)")
      | &nbsp;
      template(v-if="broadcast.stream.metadata.title")
        span using&nbsp;
        strong {{ broadcast.stream.metadata.title }}&nbsp;
      a(:href="`/customer/${customer.id}`", v-if="customer", @click.stop="goToCustomer(customer.id, $event)").d-f
        span Customer&nbsp;
        strong {{customer.name}}
</template>
<script>
import moment from 'moment';
export default {
  props: ['broadcast', 'customer'],
  data() {
    return {}
  },
  methods: {
    descriptionFormatter: function(date) {
      return `${moment(date).fromNow()}`;
    },

    descriptionDatetime(date) {
      return `(${moment(date).format('YYYY-MM-DD HH:mm')})`;
    },
    goToBroadcast(id, e) {
      if (e.ctrlKey || e.metaKey) {
        window.open(`/broadcast/${id}`, '_blank');
        return;
      }
      return this.$router.push(`/broadcast/${id}`);
    },
    goToCustomer(id, e) {
      e.preventDefault();
      if (e.ctrlKey || e.metaKey) {
        window.open(`/customer/${id}`, '_blank');
        return;
      }
      return this.$router.push(`/customer/${id}`)
    }
  }
}
</script>

<style lang="sass" scoped>
.desc
  align-items: center
  display: flex
  padding-top: 5px
  flex-wrap: wrap
a
  color: white
  display: block
  text-decoration: none
</style>