<template lang="pug">
.dashboard.mw
  div.d-f.m-t-4.between-dates
    ovp-datetime(placeholder="Set date", icon="calendar-clock", :disableTime="true" v-model="startTime", :maxDate="endTime")
    span.p-r-2.p-l-2 -
    ovp-datetime(placeholder="Set date", icon="calendar-clock", :disableTime="true", v-model="endTime", maxDate="today", :minDate="startTime")
  .d-f.m-t-1.m-b-4.mq-f
    ovp-cash-box(title="Total Revenue", :number="revenue.totalIncome", gradient="total").m-r-2.m-small
    ovp-cash-box(title="Total Customer", :number="revenue.totalCustomer", gradient="income").m-l-1.m-r-1.m-small
    ovp-cash-box(title="Total Fees", :number="revenue.totalFees", gradient="fees").m-l-2.m-small
  ovp-graph(:data="revenueGraph", v-if="revenueGraph").minimalMargin.m-b-4
  div.d-f.p-a-3.mq-f.p-b-small
    ovp-stats-box(:number="customersUpToEndDate", numberText="To end date", title="Total customers", icon="account-multiple-outline")
    ovp-stats-box(:number="customersThisPeriod", numberText="This period", title="New customers", icon="account-plus-outline")
    ovp-stats-box(:number="broadcasts.count.upcoming", title="Upcoming broadcasts", icon="video-input-component")
    ovp-stats-box(:number="broadcasts.count.past", numberText="To today", title="Completed broadcasts", icon="video")
  div.d-f.p-a-3.p-t-small
    ovp-stats-box(:number="totalTransactions", numberText="To end date", title="Total transactions", icon="cash-usd")
    ovp-stats-box(:number="totalTransactionsThisPeriod", numberText="This period", title="Total transactions", icon="cash-usd")
  .d-f.m-t-4.m-b-4.mq-f
    ovp-list-box(title="Customers", :pagination="true", @pagenumber="customersPagesFunc", :maxPages="customerPageCount", :sort="customers.sort", @sorting="customersSortFunc", :sortValues="customers.sorting", @sortOn="customersSortOnFunc", :search="true", @searching="accountSearchFunc", ).f-1.m-r-2
      ul.customers(v-if="customers.list.length > 0")
        li(v-for="customer, index in customersList", :key="customer.id")
          a(:href="`/customer/${customer.id}`", @click.prevent="openCustomer(customer.id, $event)").p-y-2.p-x-3
            h3 {{ customer.name }}
            .desc.p-t-1.d-f
              span Created at: {{ datetime(customer.created_at) }} /
              broadcast-count(:id="customer.id")
              //template(v-if="accountBroadcasts[customer.id]")
                span (Upcoming {{accountBroadcasts[customer.id].upcoming}} / Past: {{accountBroadcasts[customer.id].past}})
              //template(v-else)
                span LOADING
    ovp-list-box(title="Broadcasts", :pagination="true", @pagenumber="broadcastsPagesFunc", :link="broadcasts.pagination").f-1.m-l-1
      .d-f.f-1(slot="header")
        p.broadcastType &nbsp;(
          span(@click="fetchBroadcasts('upcoming', true)", :class="{'selected': broadcasts.type == 'upcoming'}") Upcoming
          span &nbsp;/&nbsp;
          span(@click="fetchBroadcasts('past', true)", :class="{'selected': broadcasts.type == 'past'}") Completed
          | )
      ul.broadcasts
        ovp-broadcast-list-item(v-for="broadcast in broadcasts.list", v-bind:key="broadcast.id", :broadcast="broadcast", :customer="getCustomer(broadcast.account_id)")
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {

      startTime: moment(moment().subtract(12, 'months')).format('Y-MM-DD'),
      endTime: moment().format('Y-MM-DD'),

      customersUpToEndDate: 0,
      customersThisPeriod: 0,
      totalTransactions: 0,
      totalTransactionsThisPeriod: 0,

      revenueGraph: null,
      revenue: {
        graphData: null,
        totalIncome: 0,
        totalFees: 0,
        totalCustomer: 0,
      },
      customers: {
        list: [],
        countList: [],
        count: {
          total: 0,
          new: 0,
          prenew: 0,
          diff: 0
        },
        pagination: {
          page: 1,
          perPage: 10
        },
        sort: 'desc',
        search: null,
        sortOn: 'created_at',
        sorting: ['created_at', 'name']
      },
      broadcasts: {
        count: {
          upcoming: 0,
          past: 0,
          total: 0
        },
        pagination: {
          page: 1,
          perPage: 10,
          linkHeader: null
        },
        type: 'upcoming',
        list: []
      },
      randomNum: 0,
      randomNumTwo: 0,
      intervals: null,
      broadcastsCount: {sjutton: {ppv: 0,free: 0},arton: {jan: {ppv: 0,free: 0},feb: {ppv: 0,free: 0},mars: {ppv: 0,free: 0},apr: {ppv: 0,free: 0},maj: {ppv: 0,free: 0},juni: {ppv: 0,free: 0},juli: {ppv: 0,free: 0},aug: {ppv: 0,free: 0},sep: {ppv: 0,free: 0},oct: {ppv: 0,free: 0},nov: {ppv: 0,free: 0},dec: {ppv: 0,free: 0}}}
    }
  },

  components: {
    'broadcast-count': require('../components/customer-broadcast-count.vue').default,
  },

  watch: {
    startTime() {
      this.fetchRevenue();
      this.countCustomers();
      this.fetchAllRevenue();
    },
    endTime() {
      this.fetchRevenue();
      this.countCustomers();
      this.fetchAllRevenue();
    }
  },

  async mounted() {
    //this.intervals = setInterval(() => {
      //this.fetchRevenue();
      //this.fetchBroadcastCount();
      //this.fetchCustomers();
      //this.fetchBroadcasts(this.broadcasts.type, false);
      //this.fetchAllRevenue();
    //}, 20000);

    this.fetchRevenue();
    this.fetchBroadcastCount();
    this.fetchAllRevenue();
    await this.fetchCustomers();
    this.fetchBroadcasts(this.broadcasts.type, true);
  },

  beforeDestroy() {
    clearInterval(this.intervals);
  },

  methods: {
    openCustomer(id, e) {
      if (e.ctrlKey || e.metaKet) {
        window.open(`/customer/${id}`, '_blank');
        return;
      }
      return this.$router.push(`/customer/${id}`);
    },
    /**
     * Fetch revenue for the last 12 months
     * and build up an array with each months profit
     * for us and customer
     */

    async fetchAllRevenue() {
      let revenue = await ovpFetch(`/pay/transactions/revenue?start=2016-01-01&end=${this.endTime}&filter=all`);
      let totalTransactions = 0;

      for (let currency in revenue.data) {
        if (revenue.data.hasOwnProperty(currency)) {
          totalTransactions += revenue.data[currency].total_transactions;
        }
      }

      this.totalTransactions = totalTransactions;
    },

    async fetchRevenue() {
      await ovpFetch(`/pay/transactions/revenue?start=${this.startTime}&end=${this.endTime}&filter=all`).then((revenue) => {
        // Lets just take SEK for now
        let ourFees = [];
        let customerIncome = [];
        let total = [];

        this.revenue.totalIncome = Math.round(revenue.data.SEK.total_revenue / 100);
        this.revenue.totalFees = Math.round(revenue.data.SEK.total_fees / 100);
        this.revenue.totalCustomer = Math.round(revenue.data.SEK.total_revenue_after_fees / 100);

        for (let monthly in revenue.data.SEK.break_down) {
          if (revenue.data.SEK.break_down.hasOwnProperty(monthly)) {
            let date = moment(monthly).format('Y-MM-DD');
            ourFees.push({
              x: date,
              y: Math.round(revenue.data.SEK.break_down[monthly].fees / 100)
            });
            customerIncome.push({
              x: date,
              y: Math.round(revenue.data.SEK.break_down[monthly].revenue_after_fees / 100)
            });
            total.push({
              x: date,
              y: Math.round(revenue.data.SEK.break_down[monthly].revenue / 100)
            });
          }
        }

        let totalTransactionsThisPeriod = 0;

        for (let currency in revenue.data) {
          if (revenue.data.hasOwnProperty(currency)) {
            totalTransactionsThisPeriod += revenue.data[currency].total_transactions;
          }
        }

        this.totalTransactionsThisPeriod = totalTransactionsThisPeriod;

        this.revenueGraph = [
          {
            id: 'ourfees',
            label: 'Our Fees',
            data: ourFees,
            color: 'rgba(46, 204, 113, 0.8)',
            fill: true
          },
          {
            id: 'customerincome',
            label: 'Customer Income',
            data: customerIncome,
            color: 'rgba(52, 152, 219, 0.8)',
            fill: true
          },
          {
            id: 'total',
            label: 'Total',
            data: total,
            color: 'rgba(255, 82, 82, 0.8)',
            fill: true
          }
        ];
      });
    },

    /**
     * Fetch count of all broadcasts in system
     */
    async fetchBroadcastCount() {
      await ovpFetch(`/live/broadcast/all/count`).then((broadcasts) => {
        this.broadcasts.count = broadcasts.data;
      });
    },

    /**
     * Fetch broadcasts
     */
    async fetchBroadcasts(mode, reset) {
      if (reset) {
        this.broadcasts.type = mode = (mode ? mode : this.broadcasts.type);
        this.broadcasts.pagination.linkHeader = null;
        this.broadcasts.pagination.page = 1;
      }

      await ovpFetch(`/live/broadcast/all?direction=${mode == 'past' ? 'desc' : 'asc'}&type=${mode}&page=${this.broadcasts.pagination.page}&per_page=${this.broadcasts.pagination.perPage}`, {}).then((broadcasts) => {
        if (reset) {
          this.broadcasts.pagination.linkHeader = (typeof broadcasts.headers['link'] !== 'undefined' && broadcasts.headers['link'] != '') ? broadcasts.headers['link'] : null;
        }
        this.broadcasts.list = broadcasts.data;
        /**
         * Awesome counter of PPV / Non PPV
         */
        if (mode == 'past') {
          this.broadcasts.list.forEach((broadcast) => {
            if (moment(broadcast.actual_end_time).isBetween('2017-01-01', '2017-12-31')) {
              if (broadcast.protected.pay_per_view == true) {
                this.broadcastsCount.sjutton.ppv++;
              } else {
                this.broadcastsCount.sjutton.free++;
              }
            } else if (moment(broadcast.actual_end_time).isBetween('2018-01-01', '2018-12-31')) {
              if (moment(broadcast.actual_end_time).format('M') == 0) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.jan.ppv++;
                } else {
                  this.broadcastsCount.arton.jan.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 1) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.feb.ppv++;
                } else {
                  this.broadcastsCount.arton.feb.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 2) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.mars.ppv++;
                } else {
                  this.broadcastsCount.arton.mars.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 3) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.apr.ppv++;
                } else {
                  this.broadcastsCount.arton.apr.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 4) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.maj.ppv++;
                } else {
                  this.broadcastsCount.arton.maj.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 5) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.juni.ppv++;
                } else {
                  this.broadcastsCount.arton.juni.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 6) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.juli.ppv++;
                } else {
                  this.broadcastsCount.arton.juli.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 7) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.aug.ppv++;
                } else {
                  this.broadcastsCount.arton.aug.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 9) {
                //console.log('SEPTEMBERBARN')
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.sep.ppv++;
                } else {
                  this.broadcastsCount.arton.sep.free++;
                }
              } else if (moment(broadcast.actual_end_time).format('M') == 10) {
                if (broadcast.protected.pay_per_view == true) {
                  this.broadcastsCount.arton.oct.ppv++;
                } else {
                  this.broadcastsCount.arton.oct.free++;
                }
              }
            }
          });
          console.log(this.broadcastsCount);
        }

      }).catch((error) => {});
    },

    /**
     * Fetch list of all customers
     * and check which are new or not
     */
    async fetchCustomers() {
      await ovpFetch(`/accounts`).then(async (customers) => {
        let oneMonth = moment().subtract(1, 'months');
        let twoMonths = moment().subtract(2, 'months');
        let newCustomers = 0;
        let prenewCustomers = 0;

        customers.data.forEach((customer) => (moment(customer.created_at).isBetween(twoMonths, oneMonth)) ? prenewCustomers++ : (moment(customer.created_at).isAfter(oneMonth)) ? newCustomers++ : null);

        this.customers.list = this.sortCustomers(customers.data, this.customers.sort, this.customers.sortOn);
        this.customers.count = {
          total: customers.data.length,
          new: newCustomers,
          prenew: prenewCustomers,
          diff: Math.round((newCustomers > prenewCustomers) ? ((newCustomers - prenewCustomers) / newCustomers) * 100 : '-' + ((prenewCustomers - newCustomers) / prenewCustomers) * 100)
        }

        this.countCustomers();
      });
    },

    accountSearchFunc(val) {
      this.customers.search = val;
    },

    countCustomers() {
      let customersUpToEndDate = 0;
      let customersThisPeriod = 0;
      this.customers.list.forEach((customer) => {
        if (moment(customer.created_at).isBefore(moment(this.endTime))) {
          customersUpToEndDate++;
        }
        if (moment(customer.created_at).isBetween(moment(this.startTime), moment(this.endTime))) {
          customersThisPeriod++;
        }
      });

      this.customersUpToEndDate = customersUpToEndDate;
      this.customersThisPeriod = customersThisPeriod;

    },

    prevCustomerPage() {
      if (this.customers.pagination.page != 0) {
        this.customers.pagination.page--;
      }
    },
    nextCustomerPage() {
      if (this.customers.pagination.page != this.customerPageCount) {
        this.customers.pagination.page++;
      }
    },

    customersPagesFunc(page) {
      this.customers.pagination.page = page;
    },

    broadcastsPagesFunc(page) {
      this.broadcasts.pagination.page = page;
      this.fetchBroadcasts(this.broadcasts.type, false);
    },

    datetime(date) {
      return `${moment(date).format('YYYY-MM-DD HH:mm')}`;
    },
    getCustomer(id) {
      return this.customers.list.filter(arr => {
        return arr.id === id;
      })[0];
    },

    sortCustomers(arr, type, sortOn) {
      arr.sort(function(a,b){
        return (type == 'asc') ? a[sortOn].localeCompare(b[sortOn]) : b[sortOn].localeCompare(a[sortOn]);
      });
      return arr;
    },

    customersSortOnFunc(val) {
      this.customers.sortOn = val;
      this.customers.list = this.sortCustomers(this.customers.list, this.customers.sort, val);
    },

    customersSortFunc(val) {
      this.customers.sort = val;
      this.customers.list = this.sortCustomers(this.customers.list, val, this.customers.sortOn);
    },
    contains(items, text) {
      return items.filter(function (item) {
        return text.toLowerCase().split(' ').every(function (el) {
          return item.name.toLowerCase().indexOf(el) > -1;
        });
      });
    },
  },

  computed: {
    customerPageCount() {
      if (this.customers.list.length == 0) return;
      let list = (this.customers.search) ? this.contains(this.customers.list, this.customers.search) : this.customers.list;
      return Math.floor(list.length / this.customers.pagination.perPage) + 1;
    },
    customersList() {
      if (this.customers.list.length == 0) return;
      let start = (this.customers.pagination.page - 1) * this.customers.pagination.perPage;
      let list = (this.customers.search) ? this.contains(this.customers.list, this.customers.search) : this.customers.list;
      return list.slice(start, (start + this.customers.pagination.perPage));
    }
  }
}
</script>

<style lang="sass">
.cashBox
  background-color: rgba(64, 64, 121, 0.55)
  border-radius: 3px
  color: white
  font-size: 16px
  .icon
    font-size: 22px
</style>

<style lang="sass">
.between-dates
  color: #bfbfbf
  font-size: 12px
  .flatpickr
    cursor: pointer

.customers, .broadcasts
  color: #f1f1f1
  li
    &:first-child
      margin-top: 5px
    &:hover
      background-color: rgba(255, 255, 255, 0.05)
      cursor: pointer
  a
    color: #f1f1f1
    display: block
    text-decoration: none
  h3
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  .desc
    color: rgba(255, 255, 255, 0.7)
    font-size: 12px
    i
      font-size: 16px

.broadcastType
  font-size: 12px
  line-height: 18px
  display: inline-flex
  align-items: flex-end
  color: rgba(255, 255, 255, 0.5)
  & > *
    &:hover
      cursor: pointer
  .selected
    color: white

.mq-f
  flex-wrap: wrap
  @media (min-width: 700px)
    flex-wrap: nowrap

.pager
  align-items: center
  border: 1px solid rgba(255, 255, 255, 0.15)
  border-radius: 10px
  display: inline-flex
  height: 20px
  justify-content: center
  transition: border-color .2s
  user-select: none
  width: 20px
  &:hover
    border-color: rgba(255, 255, 255, 0.5)
    cursor: pointer
.pages
  align-items: flex-end
  display: inline-flex
  font-size: 12px
  line-height: 18px
</style>