<template lang="pug">
.statsBox.d-f.ai-c.f-1
  .icon.jc-c.d-if.ai-c.m-r-2(v-if="icon")
    i(:class="'mdi mdi-'+icon")
  div
    .title {{ title }}
    .number.d-f
      ovp-animated-number(:number="number")
      span.m-l-1.numberText(v-if="numberText") {{ numberText }}
</template>

<script>
export default {
  props: ['icon', 'title', 'number', 'numberText']
}
</script>

<style lang="sass" scoped>
.statsBox
  max-width: 50%
  margin-bottom: 1rem
  @media (min-width: 700px)
    max-width: 25%
  .icon
    border-radius: 25px
    border: 2px solid rgba(255, 255, 255, 0.15)
    color: rgba(255, 255, 255, 0.5)
    font-size: 30px
    height: 50px
    width: 50px
    min-width: 50px
  .title
    color: rgba(255, 255, 255, 0.5)
    font-size: 10px
    letter-spacing: 1px
    line-height: 14px
    text-transform: uppercase
  .number
    color: white
    font-family: 'Open Sans'
    font-size: 16px
    line-height: 18px
    @media (min-width: 700px)
      font-size: 22px
      line-height: 24px
  .numberText
    color: rgba(255, 255, 255, 0.5)
    font-size: 10px
    padding-top: 1px
    text-transform: uppercase
</style>