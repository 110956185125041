<template lang="pug">
.app(:class="{'login': !loggedIn && !validatingToken}")
  template(v-if="!loggedIn && !validatingToken")
    .logo.m-b-4 Streamify Backoffice
    form.login-form
      input(v-model="loginForm.username", type="email", name="email", placeholder="Email").m-b-2
      input(v-model="loginForm.password", type="password", name="password", placeholder="Password").m-b-4
      button(@click.prevent="login") Login
  template(v-else-if="loggedIn")
    .main-menu
    .main
      header
        .mw.d-f.ai-c.jc-c
          .logo.small.m-r-4(@click="$router.push('/dashboard')")
      transition(name="fade", mode="out-in")
        router-view
</template>

<script>
export default {
  data() {
    return {
      loggedIn: false,
      loginForm: {
        username: '',
        password: ''
      },
      validatingToken: !!localStorage.getItem('auth')
    }
  },

  mounted() {
    if (this.validatingToken) {
      try {
        window.auth = JSON.parse(localStorage.getItem('auth'));
        ovpFetch(`/authorizations/verify`, {}).then(() => {
          this.validatingToken = false;
          this.loggedIn = true;
        }).catch(() => {
          window.auth = null;
          localStorage.removeItem('auth');
          this.validatingToken = false;
        });
      } catch(ex) {
        window.auth = null;
        localStorage.removeItem('auth');
        this.validatingToken = false;
      }
    }
  },

  methods: {
    login() {
      let base64 = require('base-64');
      ovpFetch(`/authorizations`, {
        method: 'POST',
        body: JSON.stringify({node: 'OVP Backoffice'}),
        headers: {
          'Authorization': `Basic ${base64.encode(this.loginForm.username + ':' + this.loginForm.password)}`
        }
      }).then((login) => {
        if (login.data.token) {
          this.loginForm = {username: '', password: ''};
          window.auth = login.data;
          localStorage.setItem('auth', JSON.stringify(login.data));
          this.loggedIn = true;
        }
      }).catch((loginError) => {});
    }
  }
}
</script>

<style lang="sass">
$mdi-font-path: "~mdi/fonts"
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500|Fira+Mono|Montserrat:200,400|Noto+Sans|Open+Sans:300')
@import '~mdi/scss/materialdesignicons.scss'
@import './styles/base.sass'
@import '~flatpickr/dist/flatpickr.css'

.fade-enter-active, .fade-leave-active
  transition: opacity .5s
.fade-enter, .fade-leave-to
  opacity: 0

html
  background-color: #2c2c54

.app
  display: flex
  height: 100%
  width: 100%
  &.login
    background-color: #35495e
    flex-direction: column
    align-items: center
    justify-content: center

.login-form
  align-items: center
  display: flex
  flex-direction: column
  min-width: 300px

  input
    background-color: rgba(255, 255, 255, 0.1)
    border: 1px solid rgba(0, 0, 0, 0.11)
    color: #ecf0f1

  button
    background-color: #2980b9
    border-top: 0;
    border-bottom: 2px solid darken(#2980b9, 2%)
    border-left: 2px solid darken(#2980b9, 2%)
    border-right: 3px solid darken(#2980b9, 2%)
    border-radius: 3px
    color: white
    outline: none
    padding: 10px 10px 8px
    display: inline-block

.main
  width: 100%

  & > header
    align-items: center
    background-color: #40407a
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.4);
    color: #ecf0f1
    display: flex
    height: 60px
    //justify-content: center

    h1
      font-family: Montserrat
      font-size: 16px
      font-weight: 400
      letter-spacing: 1px
      //padding-top: 15px
    
  .mobile-menu-toggle
    cursor: pointer
    display: inline-block
    position: absolute
    top: 32px
    left: 16px
    span
      background-color: #ecf0f1
      border-radius: 4px
      display: block
      height: 2px
      margin-bottom: 4px
      position: relative
      z-index: 1
      transform-origin: 4px 0
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background-color 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0s ease, width 0.25s ease, margin-left 0.25s ease
      width: 26px
      &:nth-child(1)
        width: 20px
        margin-left: 4px
      &:nth-child(2)
        transition: none
      &:nth-child(3)
        transform-origin: 0 100%
        width: 16px
        margin-left: 3px
</style>