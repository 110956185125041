<template lang="pug">
.graph
  canvas
</template>

<script>
import Chart from 'chart.js';
export default {
  props: ['data'],
  
  data() {
    return {
      graph: null,
      resizeTimer: null
    }
  },

  mounted() {
    let data = this.process(this.data);
    this.graph = new Chart(this.$el.querySelector('canvas').getContext('2d'), {
      type: 'line',
      data: {
        datasets: data.datasets
      },
      options: {
        scaleShowLabels: false,
        animation: {
          duration: 1000,
          easing: 'linear'
        },
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          position: 'nearest',
          intersect: false,
          mode: 'x'
        },
        legend: { display: false },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                displayFormats: {
                  //'hour': 'DD hh:ss',
                  //'day': 'MMM DD',
                  //'week': 'MMM DD',
                  'month': 'MMM YY',
                  //'year': 'MMM DD'
                },
                //round: 'day'
              },
              ticks: {
                fontColor: '#fafaff',
                fontSize: '10',
                fontFamily: 'Roboto'
              },
              gridLines: {
                display: true,
                color: 'rgba(255, 255, 255, 0.05)'
              }
            }
          ],
          yAxes: data.yAxes
        }
      }
    });

    window.addEventListener('resize', this.resizeGraph, false);
  },

  beforeDestroy() {
    clearTimeout(this.resizeTimer);
    window.removeEventListener('resize', this.resizeGraph, false);
  },

  watch: {
    data(newData, oldData) {
      if (JSON.stringify(newData) == JSON.stringify(oldData)) return
      let data = this.process(newData);
      this.graph.data.datasets = data.datasets;
      this.graph.options.scales.yAxes = data.yAxes;
      this.graph.update();
    }
  },

  methods: {
    resizeGraph() {
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        this.graph.resize();
      }, 200);
    },

    lineSettings(obj) {
      return Object.assign({
        backgroundColor: obj.borderColor,
        pointBackgroundColor: obj.borderColor,
        pointBorderColor: '#ecf0f1',
        pointHoverBackgroundColor: obj.borderColor,
        pointHoverBorderColor: obj.borderColor,
        pointRadius: 5,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBorderWidth: 2,
        borderWidth: 2,
        lineTension: 0.25,
        fill: false,
      }, obj);
    },

    process(raw) {
      let datasets = [];
      let yAxes = [];
      let yMax = 0;

      raw.forEach((set) => {
        datasets.push(this.lineSettings({
          label: set.label,
          data: set.data,
          borderColor: set.color,
          yAxisID: set.id,
          fill: (set.fill) ? true : false
        }));

        yAxes.push({
          type: 'linear',
          display: false,
          ticks: { beginAtZero: true },
          position: 'left',
          id: set.id,
          gridLines: { display: false, drawTicks: false }
        });

        let maxValue = Math.round(Math.max.apply(null, set.data.map(function(d) { return d.y; })) * 1.1);
        yMax = (maxValue > yMax) ? maxValue : yMax;
      });

      yAxes.forEach((axis) => {
        axis.ticks.max = (yMax > 0) ? yMax : 100;
      });

      return {datasets, yAxes}
    }
  }
}
</script>

<style lang="sass" scoped>
.graph
  height: 250px
  padding: 30px 0 5px
  position: relative
  &.minimalMargin
    margin-left: -15px
    margin-right: -15px
  @media (min-width: 700px)
    height: 300px
    padding: 30px 0 10px
  @media (min-width: 900px)
    height: 350px
    padding: 30px 0 15px
  @media (min-width: 1100px)
    height: 450px
    padding: 30px 0 15px
</style>