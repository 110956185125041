<template lang="pug">
.customer(v-if="customer").mw
  div.d-f.m-t-4.between-dates
    ovp-datetime(placeholder="Set date", icon="calendar-clock", :disableTime="true" v-model="startTime", :maxDate="endTime")
    span.p-r-2.p-l-2 -
    ovp-datetime(placeholder="Set date", icon="calendar-clock", :disableTime="true", v-model="endTime", maxDate="today", :minDate="startTime")
  ovp-graph(:data="revenueGraph", v-if="revenueGraph").minimalMargin.m-b-4
  template(v-else)
    p.p-y-4.ta-c(style="color: rgba(255, 255, 255, 0.6; font-size: 12px; text-transform: uppercase;") No graphdata right now..
  .p-y-4
    h1(style="font-size: 2rem; line-height: 2.2rem; text-transform: uppercase;") {{ customer.name }}
  .d-f.m-t-4.m-b-4
    ovp-list-box(title="Broadcasts", :pagination="true", @pagenumber="broadcastsPagesFunc", :link="broadcasts.pagination").f-1.m-r-1
      .d-f.f-1(slot="header")
        p.broadcastType &nbsp;(
          span(@click="fetchBroadcasts('upcoming', true)", :class="{'selected': broadcasts.type == 'upcoming'}") Upcoming
          span &nbsp;/&nbsp;
          span(@click="fetchBroadcasts('past', true)", :class="{'selected': broadcasts.type == 'past'}") Completed
          | )
      ul.broadcasts
        ovp-broadcast-list-item(v-for="broadcast in broadcasts.list", v-bind:key="broadcast.id", :broadcast="broadcast")
    ovp-list-box(title="Information").f-1.m-l-2
      .desc.p-x-3.p-t-2
        p
          strong(style="color: white; font-weight: bold; font-size: 12px") FQDN
      ul.customers
        li.p-x-3
          .desc.p-t-1
            p.p-a-0.m-a-0 {{ customer.fqdn }}
      .desc.p-x-3.p-t-2
        p
          strong(style="color: white; font-weight: bold; font-size: 12px") Contacts
      ul.customers(v-if="contacts.length > 0")
        li(v-for="contact in contacts", :key="contact.id").p-y-0.p-x-3
          h3 {{ contact.full_name }}
          .desc.p-t-1.d-f
            span {{ contact.email }}
  .d-f.m-t-4.m-b-4
    ovp-list-box(title="Revenue split").f-1.m-l-2
      .p-y-1.p-y-2.p-x-3
        span Application fee
        .d-f.m-b-2.ai-c.jc-c
          input(type="range", min="0", :max="1", step="0.01", v-model="applicationFee", style="padding: 0; border: 0")
          span(style="min-width: 70px; text-align: right") {{ applicationFee }}
        span Reseller fee
        .d-f.m-b-2.ai-c.jc-c
          input(type="range", min="0", :max="1 - applicationFee", step="0.01", v-model="resellerFee", style="padding: 0; border: 0")
          span(style="min-width: 70px; text-align: right") {{ resellerFee }}
        .m-b-2(v-for="(step, index) in applicationFeeSteps")
          span Application fee step
          .d-f.ai-c.jc-c
            input(type="number", v-model="step.applicable_from")
            input(type="range", min="0", :max="1 - resellerFee", step="0.01", v-model="step.application_fee", style="padding: 0; border: 0")
            span(style="min-width: 70px; text-align: right") {{ step.applicable_from }} / {{ step.application_fee }}
            span(@click="removeApplicationFeeStep(index)") x
        .d-f
          button(@click="addApplicationFeeStep").p-a-2.add-afs Add application fee step
          button(@click="saveRevenueSplit").p-a-2.ml-auto.save-r Save revenue split
</template>

<script>
import moment from 'moment';
export default {
  props: ['id'],
  data() {
    return {
      customer: null,
      revenueGraph: null,
      startTime: moment(moment().subtract(12, 'months')).format('Y-MM-DD'),
      endTime: moment().format('Y-MM-DD'),
      contacts: [],
      broadcasts: {
        count: {
          upcoming: 0,
          past: 0,
          total: 0
        },
        pagination: {
          page: 1,
          perPage: 10,
          linkHeader: null
        },
        type: 'upcoming',
        list: []
      },
      blueprints: [],
      transactions: [],
      applicationFee: 0,
      applicationFeeSteps: [],
      resellerFee: 0
    }
  },

  watch: {
    startTime() {
      this.fetchRevenue();
    },
    endTime() {
      this.fetchRevenue();
    }
  },

  mounted() {
    ovpFetch(`/accounts/${this.id}`).then(async (customer) => {
      this.customer = customer.data;
      this.fetchRevenue();
      this.fetchBroadcasts(this.broadcasts.type, false);
      this.fetchTransactions();
      this.fetchRevenueSplit();
      this.fetchContacts()
    }).catch((customerError) => {});
  },

  methods: {

    fetchRevenue() {
      ovpFetch(`/pay/transactions/revenue?start=${this.startTime}&end=${this.endTime}&account_id=${this.customer.id}`).then((revenue) => {
        // Lets just take SEK for now
        let ourFees = [];
        let customerIncome = [];
        let total = [];

        if (!revenue.data.SEK) return;

        for (let monthly in revenue.data.SEK.break_down) {
          if (revenue.data.SEK.break_down.hasOwnProperty(monthly)) {
            let date = moment(monthly).format('Y-MM-DD');
            ourFees.push({
              x: date,
              y: Math.round(revenue.data.SEK.break_down[monthly].fees / 100)
            });
            customerIncome.push({
              x: date,
              y: Math.round(revenue.data.SEK.break_down[monthly].revenue_after_fees / 100)
            });
            total.push({
              x: date,
              y: Math.round(revenue.data.SEK.break_down[monthly].revenue / 100)
            });
          }
        }

        this.revenueGraph = [
          {
            id: 'ourfees',
            label: 'Our Fees',
            data: ourFees,
            color: 'rgba(46, 204, 113, 0.8)',
            fill: true
          },
          {
            id: 'customerincome',
            label: 'Customer Income',
            data: customerIncome,
            color: 'rgba(52, 152, 219, 0.8)',
            fill: true
          },
          {
            id: 'total',
            label: 'Total',
            data: total,
            color: 'rgba(255, 82, 82, 0.8)',
            fill: true
          }
        ];
      });
    },

    fetchContacts() {
      /*ovpFetch(`/accounts/${this.customer.id}/users`).then((response) => {
        this.contacts = response.data;
      });*/
      ovpFetch(`/users?account_id=${this.customer.id}`).then((response) => {
        this.contacts = response.data;
      });
    },

    fetchTransactions() {
      ovpFetch(`/pay/transactions?direction=desc&account_id=${this.customer.id}&start=${this.startTime}&end=${this.endTime}&page=1&per_page=10`, {}).then((response) => {
        this.transactions = response.data;
      });
    },

    fetchRevenueSplit() {
      ovpFetch(`/account-manager/stripe/fee/${this.customer.id}`).then((split) => {
        this.applicationFee = split.data.application_fee;
        this.resellerFee = (typeof split.data.reseller_account_fee !== 'undefined') ? split.data.reseller_account_fee : 0;
        this.applicationFeeSteps = (typeof split.data.application_fee_steps !== 'undefined') ? split.data.application_fee_steps : [];
      });
    },

    addApplicationFeeStep() {
      this.applicationFeeSteps.push({
        applicable_from: 0,
        application_free: 0
      });
    },

    removeApplicationFeeStep(index) {
      if (this.applicationFeeSteps.length >= 1) {
        this.applicationFeeSteps.splice(index, 1);
      }
    },

    saveRevenueSplit() {
      let payload = {
        application_fee: parseFloat(this.applicationFee),
        reseller_account_fee: parseFloat(this.resellerFee)
      };

      if (this.applicationFeeSteps.length > 0) {
        payload.application_fee_steps = [];
        this.applicationFeeSteps.forEach((step) => {
          payload.application_fee_steps.push({
            applicable_from: parseInt(step.applicable_from),
            application_fee: parseFloat(step.application_fee)
          });
        });
      }

      ovpFetch(`/account-manager/stripe/fee/${this.customer.id}`, {
        method: 'POST',
        body: JSON.stringify(payload)
      }).then((payloadResponse) => {}).catch((payloadError) => {})
    },

    fetchBroadcasts(mode, reset) {
      if (reset) {
        this.broadcasts.type = mode = (mode ? mode : this.broadcasts.type);
        this.broadcasts.pagination.linkHeader = null;
        this.broadcasts.pagination.page = 1;
      }

      ovpFetch(`/live/broadcast?direction=desc&type=${mode}&page=${this.broadcasts.pagination.page}&per_page=${this.broadcasts.pagination.perPage}&account_id=${this.customer.id}`, {}).then((broadcasts) => {
        if (reset) {
          this.broadcasts.pagination.linkHeader = (typeof broadcasts.headers['link'] !== 'undefined' && broadcasts.headers['link'] != '') ? broadcasts.headers['link'] : null;
        }
        this.broadcasts.list = broadcasts.data;
      }).catch((error) => {});
    },

    broadcastsPagesFunc(page) {
      this.broadcasts.pagination.page = page;
      this.fetchBroadcasts(this.broadcasts.type, false);
    },

    datetime(date) {
      return `${moment(date).format('YYYY-MM-DD HH:mm')}`;
    }
  }
}
</script>

<style lang="sass" scoped>
.add-afs
  background-color: rgba(0, 255, 0, 0.5)
.save-r
  background-color: rgba(255, 255, 255, 0.2)
  color: rgba(255, 255, 255, 0.7)
</style>